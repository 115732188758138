
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.layout-client-portals {
  width: 202px;
  padding: 16px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    text-transform: uppercase;

    @include for-size(phone-portrait-down) {
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @include for-size(phone-portrait-down) {
        margin-bottom: 24px;
      }
    }

    &-icon {
      display: inline-block;
      width: auto;
      height: auto;
      max-width: 24px;
      max-height: 24px;
      margin-right: 10px;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
